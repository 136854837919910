import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Open Workout 21.1`}</strong></p>
    <p>{`For time:`}</p>
    <p>{`1 Wall Walk`}</p>
    <p>{`10 Double Unders`}</p>
    <p>{`3 Wall Walks`}</p>
    <p>{`30 Double Unders`}</p>
    <p>{`6 Wall Walks`}</p>
    <p>{`60 Double Unders`}</p>
    <p>{`9 Wall Walks`}</p>
    <p>{`90 Double Unders`}</p>
    <p>{`15 Wall Walks`}</p>
    <p>{`150 Double Unders`}</p>
    <p>{`21 Wall Walks`}</p>
    <p>{`210 Double Unders`}</p>
    <p>{`15:00 Time Cap.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`go to Games.CrossFit.com for more info on standards and workout
variations.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      